import { ErrorHandler, Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastMessageService } from './shared/services/toast-message.service';
import { SessionStorageService } from './shared/services/session-storage.service';
import { RouterService } from 'src/app/shared/services/router.service';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(
    public toastMessageService: ToastMessageService,
    private sessionStorage: SessionStorageService,
    private routerService: RouterService
    ) { }

    handleError(error: any): void {
        // Handle different types of errors accordingly
        if (error instanceof HttpErrorResponse) {
            // Handle HTTP errors
           if(error.status === 400){
            this.toastMessageService.showErrorMessage('There was a problem with your request. Please check the information you provided and try again.');
          } else if (error.status === 401) {
              this.toastMessageService.showErrorMessage('Unauthorized access. Please log in again.');
          } else if (error.status === 403) {
              this.toastMessageService.showErrorMessage('You do not have permission to access this resource.');
          } else if (error.status === 404) {
              this.toastMessageService.showErrorMessage('Requested resource not found.');
          } else if (error.status >= 500) {
              this.toastMessageService.showErrorMessage('Server error occurred. Please try again later.');
          }
        } else {
            // Handle non-HTTP errors
            // this.toastMessageService.showErrorMessage("An unexpected error has occurred. Please try again later or contact support if the issue persists.");
        }

        // Optionally, log the error to an external service
        // e.g., a logging service or a remote server
        
        // Throw the error again if you want it to propagate
        // throw error;
    }
}
