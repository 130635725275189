export enum OperationEnum {
  View,
  Add,
  Modify,
  Delete,
}

export enum RoleEnum {
  Admin = 'Admin',
  User = 'User',
  Viewer = 'Viewer',
  Approver = 'Approver',
  Auditor = 'Auditor',
  AuditCommitteeMember = 'Audit Committee Member',
  Reviewer = 'Reviewer',
  CompanySecretary = 'Company Secretary',
  Director = 'Director',
  ComplianceOfficer = 'Compliance Officer',
  DesignatedPerson = 'Designated Person',
  UPSISPOCAssignee = 'UPSI SPOC Assignee',
  UPSISPOC = 'UPSI SPOC',
  INSIDER = 'Insider',
  SHARE_HOLDER= 'Share Holder',
  CM = 'Chairman',
  SecretarialAdmin='Secretarial Admin',
  SpecialComplianceOfficer='Special Compliance Officer',
}

export enum ModulesEnum {
  Admin = 'Admin',
  User = 'User',
  Viewer = 'Viewer',
  Approver = 'Approver',
  Auditor = 'Auditor',
  Reviewer = 'Reviewer',
  CompanySecretary = 'Company Secretary',
  Director = 'Director',
  ComplianceOfficer = 'Compliance Officer',
  DesignatedPerson = 'Designated Person'
}

export enum DateFormatEnum {
  DDMMYYYY = 'DD/MM/YYYY',
  MMDDYYYY = 'MM/DD/YYYY',
  YYYYMMDD = 'YYYY/MM/DD',
  YYYY_MM_DD = 'YYYY-MM-DD',
  DDMMMYYYY = 'DD MMM YYYY',
  DDMYYYY = 'dd-M-yy',
  MMMYY = 'M-y',
  MMMDYYYY = 'MM d, yy',
  ddMMYYYY = 'dd/mm/yy'
}

export enum CurrencyEnum {
  USD = 'USD',
  INR = 'INR',
  EUR = 'EUR',
}

export enum WorkFlowModules {
  RESTRICTED_LIST = 'Restricted List',
  SDD_UPSI_SHARING = 'SDD UPSI Sharing',
  TRADING_WINDOW_CLOSURE = 'Trading Window Closure',
  ISSUE_OF_SECURITY = 'Issue of Security',
  PRECLEARANCE='Preclearance',
  PERSONAL_DETAILS = 'Personal Details',
  TRADE_INTIMATION = 'Trade Intimation',
  TRADE_PLAN = 'Trade Plan',
  MCAOnboarding = 'Onboarding MCA',
  MCAClientDetails = 'Client Details MCA',
  MCAHandD = 'Holding And Subsidiary',
  CLIENT_ONBOARDING = 'Company Onboarding'
}

export enum NotificationTemplate{
  PreClearanceSendReminder='Preclearance - SendReminder',
  PreClearanceSendReminderAcknowladge='Preclearance - ReminderAcknowledgement',
  PreClearanceSuccessMailNew='Preclearance - Pending Approval - DP',
  PreClearanceApprovedMail='Preclearance - Approved',
  PreClearanceRejectedMailNew='Preclearance - Rejected',
  TradeIntimationTradeNotPerformedByDPMail='Trade Intimation - Trade Not Performed By DP',
  PreClearanceSuccessMailEdit='Preclearance - Pending Approval - DPEdit',
  TradeIntimationSuccessMailNew = 'Trade Intimation - Pending Review - DP',
  TradeIntimationSuccessMailEdit = 'Trade Intimation - Noted',
  KMPOnboardInsider = 'KMP - Onboard Insider',
  KMPOnboardDesignatedPerson = 'KMP - Onboard Designated Person',
  KMPOnboardSignatory = 'KMP - Onboard Signatory',
  MCANotification = 'MCA - Notification'
}

export enum EmailTemplateEnum {
  TradingWindowIntimationEmail = 'TradingWindowIntimationEmail',
  TradingWindowClosureRevisedEmail = 'TradingWindowClosureRevisedEmail',
  StockExchangeIntimation = 'StockExchangeIntimation',
  StockExchangeIntimationWithoutBM = 'StockExchangeIntimationWithoutBM',
  StockExchangeIntimationRevised = 'StockExchangeIntimationRevised',
  RestrictedListNewIntimation = 'Restricted List - New Intimation',
  BenposMismatchIntimation = 'Benpos Mismatch - Intimation',
  TradingWindowIntimationEmailWithoutBM  = 'TradingWindowIntimationEmailWithoutBM',
  TradingPlanFormat = 'TradingPlanFormat',
  TradingPlanFormatCO = 'TradingPlanFormat - CO',
  TradingWindowIntimationEmailEdited = 'TradingWindowIntimationEmailEdited',
  TradingWindowIntimationEmailEditedWithoutBM = 'TradingWindowIntimationEmailEditedWithoutBM'
}

export enum PDFModulesEnum {
  StockExchangeIntimation = 'Stock Exchange Notification',
  TradePlan = 'Trade Plan'
}

export enum ModulesEnum {
  //ECG = 'Entity Compliance And Governance',
  //POIT = 'Prohibition of Insider Trading',
  ECG = 'EY Digi Secretarial Compliance Tool',
  POIT = 'EY Digi Insider Trading Regulatory Compliance Tool',
  LD = 'Learning & Development',
  SDD = 'SDD UPSI Sharing',
}

export enum ModulesShortFormEnum {
  ECG = 'ECG',
  POIT = 'POIT',
  LD = 'Learning & Development',
  SDD = 'SDD',
  POIT_ECG = 'POIT_ECG',
}

export enum OfferingEnum {
  TransactionTracker = 'Transaction Tracker',
  COSEC = 'COSEC',
  GRC = 'GRC',
  ComplEye = 'ComplEye',
}

export enum ModuleEnum {
  Entitlement = 'Entitlement',
  Deployment = 'Deployment',
  Licensing = 'License Engine',
  Process = 'Process',
  Request = 'Request',
}

export enum LicensingWorkflowEnum {
  ELPPublish = 'ELP Publish',
}

export enum GoldenFamilyEnum {
  ModuleList = 'Module',
  Country = 'Country',
  CompanyTypes = 'Company Types',
  CompanyEssentials = 'Company Essentials',
  MeetingEssentials = 'Meeting Essentials',
  Transactions = 'Transactions',
  FieldsInventory = 'Fields Inventory',
}

export enum GoldenKeyEnum {
  AssetType = 'Asset Type',
  Country = 'Country',
  State = 'State',
  Region = 'Region',
  HostType = 'Host Type',
  VirtualizationPlatform = 'Virtualization Platform',
  AssetDesignation = 'Asset Designation',
  SoftwareType = 'Software Type',
  OperatingSystem = 'Operating System',
  ClusterNames = 'Cluster Names',
  PhysicalServerName = 'Physical Server Name',
  AssetModel = 'Asset Model',
  DataCenterLocation = 'Data Center Location',
  ProcessorMake = 'Processor Make',
  ProcessorModel = 'Processor Model',
  ProcessorCount = 'Processor Count',
  CoresPerProcessor = 'Cores Per Processor',
  SharedPoolID = 'Shared Pool ID',
  TotalCoresInSharedPool = 'Total Cores In Shared Pool',
  EntitledCapacity = 'Entitled Capacity',
  VirtualProcessorCores = 'Virtual Processor Cores',
  LPARType = 'LPAR Type',
  LPARMode = 'LPAR Mode',
  FederalDistrict = 'Federal District',
  Inhabitedterritories = 'Inhabited territories',
  Kingdom = 'Kingdom',
  Prefectures = 'Prefectures',
  DocumentType = 'DocumentType',
  DeploymentDeclaration = 'DeploymentDeclaration',
  Module = 'Module',
  OracleCoreFamily = 'OracleCoreFamily',
  IBMPVUFamily = 'IBMPVUFamily',
  vMotion = 'vMotion'
}

export enum GoldenValueEnum {
  ServerOperatingSystem = 'Server Operating System',
  ClientOperatingSystem = 'Client Operating System'
}

export enum GlobalDateFormatEnum {
  DDMMYYYY = 'DD/MM/YYYY',
  MMDDYYYY = 'MM/DD/YYYY',
  YYYYMMDD = 'YYYY/MM/DD',
  YYYY_MM_DD = 'YYYY-MM-DD',
  DDMMMYYYY = 'DD MMM YYYY',
  MMMYY = 'M-y',
  MMMDYYYY = 'MM d, yy'
}

export enum PreClearancePagingEnum{
  pageSize=10
}

export enum ReportsEnum{
  PAN="PAN",
  Individual="Individual",
  PeriodicEventReport = "Periodic / Event based disclosure Report"

}

export enum ReportsHeadersEnum{
  ViolationHeader='Violation Report',
  UPSIHeader='UPSI Sharing Report',  
    DpHeader = 'DESIGNATION PERSON REPORT',
    PeriodicEventReport = "Periodic / Event based disclosure Report",
  ChangeInDOInHeader='Change in Designated Person / Insider report',
  PreClearanceHeader = 'Pre-Clearance Report',
TWCHeader="Trading Window Closure Report",
    InsiderHeader = "Insider Report",
    PreClearanceReport = "PRE-CLEARANCE REPORT",
    RestrictedHeader = "RESTRICTED LIST REPORT",
  IssueandDisposalReport = 'Issue And Disposal Of Securities Report',
    TradePlanReport='Trade Plan Report',
    TIReport='Trade Intimation Report',
 UPSIandTradingHeader = "UPSI sharing & Trading by DP Report",
  BenPosHeader = 'BENPOS DISCREPANCY REPORT',
  BenPosWeeksHeader = 'BENPOS WEEKLEY COMPARISION REPORT'
}
export enum DisClosureFilter {
    UserType = "UserType",
    DisclosureType = "DisclosureType"
}

export enum SortingType {
  Asc = "Ascending",
  Desc  = "Descending"
}

export enum ExcludeCloumn {
  TWCPeriod = "TWCPeriod",
  DateOfTI = "DateOfTI",
  UPSIShared = "UPSIShared",
  DateOfSharing = "DateOfSharing",
  NatureOfUPSI = "NatureOfUPSI",
  NatureOfTransaction = "NatureOfTransaction",
  UPSIROLE = "UpsiRole"

}


export enum ToolNameEnum {
  ToolName = 'EY Digi Insider Trading Regulatory Compliance Tool (EY Digi ITRCT)'
}
export enum TableColumnFilter {
  ComplianceStatus = "Non-Compliance"
}


export enum AbbreviationListEnum{
  DPAbbreviation='DP',
  DPExpandedForm='Designated Person',
  UPSIAbbreviation='UPSI',
  UPSIExpandedForm ='Unpublished Price Sensitive Information',
  PCAbbreviation ='PC',
  PCExpandedForm ='Pre-Clearance',
  TIAbbreviation='TI',
  TIExpandedForm='Trade Intimation',
  COAbbreviation='CO',
  COExpandedForm='Compliance Officer',
  IRAbbreviation='IR',
  IRExpandedForm='Immediate Relative',
  MFRAbbreviation='MFR',
  MFRExpandedForm='Material Financial Relationship',
  NCAbbreviation='NC',
  NCExpandedForm='Non Compliance'
}