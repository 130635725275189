// Angular modules
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';

import { CommonComponentsModule } from '../common/common-components.module';

// Core module routing
import { CoreRoutingModule } from './core-routing.module';

// Core module components
import { HeaderComponent } from './components/header/header.component';
import { BodyComponent } from './components/body/body.component';
import { FooterComponent } from './components/footer/footer.component';
import { LoggingInComponent } from './components/logging-in/logging-in.component';
import { LoginComponent } from './components/login/login.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { LoginStatusComponent } from './components/login-status/login-status.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { NavMenuComponent } from './components/nav-menu/nav-menu.component';

// Doormat menu components
// tslint:disable-next-line: max-line-length
import { DoormatPopupCoSecEYAdminComponent } from '../zibble/cosec/doormat/ey-admin/components/doormatpopupcoseceyadmin.component';
// tslint:disable-next-line: max-line-length
import { DoormatPopupCoSecClientAdminComponent } from '../zibble/cosec/doormat/client-admin/components/doormatpopupcosecclientadmin.component';
// tslint:disable-next-line: max-line-length
import { DoormatPopupCoSecCompanySecretaryComponent } from '../zibble/cosec/doormat/company-secretary/components/doormatpopupcoseccompanysecretary.component';
// tslint:disable-next-line: max-line-length
import { DoormatPopupCoSecComplianceOfficerComponent } from '../zibble/cosec/doormat/compliance-officer/components/doormatpopupcoseccomplianceofficer.component';
// tslint:disable-next-line: max-line-length
import { DoormatPopupCoSecDesignatedPersonComponent } from '../zibble/cosec/doormat/designated-person/components/doormatpopupcosecdesignatedperson.component';
// tslint:disable-next-line: max-line-length
import { DoormatPopupCoSecInsiderComponent } from '../zibble/cosec/doormat/insider/components/doormatpopupcosecinsider.component';
// tslint:disable-next-line: max-line-length
import { DoormatPopupCoSecUPSISPOCComponent } from '../zibble/cosec/doormat/dd-spoc/components/doormatpopupcosecddspoc.component';
// tslint:disable-next-line: max-line-length
import { DoormatPopupCoSecUPSISPOCAssigneeComponent } from '../zibble/cosec/doormat/dd-assignee/components/doormatpopupcosecddassignee.component';
// tslint:disable-next-line: max-line-length
import { DoormatPopupCoSecSecretarialAdminComponent } from '../zibble/cosec/doormat/secretarial-admin/components/doormatpopupcosecsecretarialadmin.component';
// tslint:disable-next-line: max-line-length
import { DoormatPopupCoSecSpecialComplianceOfficerComponent } from '../zibble/cosec/doormat/special-compliance-officer/components/doormatpopupcosecspecialcomplianceofficer.component';

// Shared service
import { TokenInterceptor } from '../shared/interceptors/token.interceptor';
import { HttpService } from '../shared/services/http.service';
import { RouterService } from '../shared/services/router.service';
import { SessionStorageService } from '../shared/services/session-storage.service';
import { LoginService } from './services/login.service';
import { DataService } from '../shared/services/data.service';
import { ProgressBarService } from './services/progress-bar.service';
// Prime ng modules
import { DialogModule } from 'primeng/dialog';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { PasswordModule } from 'primeng/password';
import { TableModule } from 'primeng/table';
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';
import { ProgressBarModule } from 'primeng/progressbar';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { DropdownModule } from 'primeng/dropdown';
import { MegaMenuModule } from 'primeng/megamenu';
import { TooltipModule } from 'primeng/tooltip';

import { PanelMenuModule } from 'primeng/panelmenu';

import { ResponseMessages } from '../shared/constants/response-messages';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { LocalStorageService } from '../shared/services/local-storage.service';
import { AuthGuardService } from '../shared/services/auth-guard.service';
import { ForbiddenPageComponent } from './components/forbidden-page/forbidden-page.component';
import { UnauthorizedPageComponent } from './components/unauthorized-page/unauthorized-page.component';
import { EncryptDecryptService } from '../shared/services/encrypt-decrypt.service';
import { Md5HashService } from '../shared/services/md5-hash.service';
import { ToastMessageService } from '../shared/services/toast-message.service';
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';
import { ErrorPageComponent } from './components/error-page/error-page.component';
import { NotificationDialogComponent } from './components/notification-dialog/notification-dialog.component';
import {SelectButtonModule} from 'primeng/selectbutton';
import { LogoutComponent } from './components/logout/logout.component';
import { NgHcaptchaModule } from 'ng-hcaptcha'
import { ReCaptcha2Component } from 'ngx-captcha';
import { NgxCaptchaModule } from 'ngx-captcha';
// import { RecaptchaModule } from "ng-recaptcha";
import {ReactiveFormsModule } from '@angular/forms';



@NgModule({
	imports: [
		// Angular modules
		// RecaptchaModule,
		NgHcaptchaModule.forRoot({ siteKey: '6Lfif2oqAAAAAJyZzA7dFX0fuVWBbGM3o8Bm_gS1' }),
		NgxCaptchaModule,
		ReactiveFormsModule,
		CommonModule
		, FormsModule
		, CommonModule
		, HttpClientModule
		, BrowserAnimationsModule

		// Application modules
		, CoreRoutingModule
		, CommonComponentsModule

		// Primeng modules
		, DialogModule
		, ButtonModule
		, InputTextModule
		, PasswordModule
		, MessagesModule
		, MessageModule
		, TableModule
		, ToastModule
		, PanelMenuModule
		, ProgressBarModule
		, ProgressSpinnerModule
		, DropdownModule
		, MegaMenuModule
		, SelectButtonModule
		,TooltipModule

	],
	exports: [
		HeaderComponent
		, FooterComponent
		, RouterModule
	],
	declarations: [
		HeaderComponent
		, BodyComponent
		, FooterComponent
		, LoggingInComponent
		, LoginComponent
		, ForgotPasswordComponent
		, ResetPasswordComponent
		, LoginStatusComponent
		, NavMenuComponent
		, ForbiddenPageComponent
		, UnauthorizedPageComponent
		, ProgressBarComponent
		, ErrorPageComponent
		, DoormatPopupCoSecEYAdminComponent
		, DoormatPopupCoSecClientAdminComponent
		, DoormatPopupCoSecCompanySecretaryComponent
		, DoormatPopupCoSecComplianceOfficerComponent
		, DoormatPopupCoSecDesignatedPersonComponent
		, DoormatPopupCoSecInsiderComponent
		, DoormatPopupCoSecUPSISPOCComponent
		, DoormatPopupCoSecUPSISPOCAssigneeComponent
		, DoormatPopupCoSecSecretarialAdminComponent
		, DoormatPopupCoSecSpecialComplianceOfficerComponent, NotificationDialogComponent, LogoutComponent,
	],
	providers: [
		{
			provide: HTTP_INTERCEPTORS,
			useClass: TokenInterceptor,
			multi: true
		},

		// Prime ng service
		MessageService,
		// Shared services
		HttpService,
		Md5HashService,
		EncryptDecryptService,
		RouterService,
		SessionStorageService,
		LocalStorageService,
		AuthGuardService,
		ResponseMessages,
		ToastMessageService,
		DataService,

		// Component based services
		LoginService,
		ProgressBarService
	]
})
export class CoreModule {

}
