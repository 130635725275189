import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { SessionStorageService } from '../services/session-storage.service';
import { LocalStorageService } from '../services/local-storage.service';
import { EncryptDecryptService } from '../services/encrypt-decrypt.service';
import { RouterService } from 'src/app/shared/services/router.service';
import { ToastMessageService } from 'src/app/shared/services/toast-message.service';



@Injectable({
  providedIn: 'root',
})
export class TokenInterceptor implements HttpInterceptor {
  constructor(
    private sessionStorage: SessionStorageService,
    private localStorageService: LocalStorageService,
    private encryptDecryptService: EncryptDecryptService,
    private router: Router,
    private routerService: RouterService,
    public toastMessageService: ToastMessageService
  ) {}

  private setBasicHeaders(headers: HttpHeaders) {
    const token = this.localStorageService.authToken;
    headers.set('Authorization', `Bearer ${token}`);
    headers.append('Accept', 'application/json');
    headers.append('content-type', 'Application/Json');
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // const SessionToken = sessionStorage.getItem('Token');
    if (!this.localStorageService.authToken) {
      request = request.clone({ withCredentials: true });
    } else {
      if (
        ['UploadPhoto', 'upload', 'uploadHeaderFooterLogo', 'uploadHeaderFooterLogoPreview'].includes(
          request.url.substring(request.url.lastIndexOf('/') + 1)
        )
      ) {
        request = request.clone({
          setHeaders: {
            Authorization: `Bearer ${this.localStorageService.authToken}`,
            UId: this.encryptDecryptService.encrypt<string>(this.localStorageService.userDetails.Email).toString(),
            SId: this.localStorageService.userDetails.sessionId,
          },
        });
      } else {
        request = request.clone({
          setHeaders: {
            Authorization: `Bearer ${this.localStorageService.authToken}`,
            'Content-Type': 'application/json',
            UId: this.encryptDecryptService.encrypt<string>(this.localStorageService.userDetails.Email).toString(),
            SId: this.localStorageService.userDetails.sessionId
          },
        });
      }
    }
    //  return next.handle(request);
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {          
      
        if (error.status === 401 && error.error.includes("##Session##")) {
          // Handle 401 error by logging out the user   
          var CheckError=error.error.replace(/##Session##/g, "");
          if (CheckError !== this.localStorageService.userDetails.sessionId) {
          debugger;              
		      this.sessionStorage.flushOnLogout();
		       sessionStorage.clear();
		      localStorage.clear();          
		       this.toastMessageService.showInfoMessage('Session inactive or account deleted. Contact support for help.');
		       this.routerService.RedirectLogin();
        }
        }
        return throwError(() => error); // Rethrow the error if it's not 401, or if you want it handled elsewhere
      })
    );
  }
}
