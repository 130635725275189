import { Component, OnInit, Input, ViewEncapsulation, OnChanges, SimpleChanges } from '@angular/core';
import { Subscription } from 'rxjs';
import { SessionStorageService } from 'src/app/shared/services/session-storage.service';
import { RouterService } from 'src/app/shared/services/router.service';
import { takeWhile } from 'rxjs/operators';
import { RoleEnum } from 'src/app/shared/constants/enum';
import { DataService } from 'src/app/shared/services/data.service';

@Component({
  selector: 'app-welcome-user',
  templateUrl: './welcome-user.component.html',
  styleUrls: ['./welcome-user.component.scss'],
  // encapsulation: ViewEncapsulation.None
})
export class WelcomeUserComponent implements OnInit, OnChanges {

  private isSubscriptionAlive = false;
  public isLoggedIn: boolean;
  public showProfileDropdown = false;
  public showSwitchRole: boolean;
  private userDetailsSubscription: Subscription;
  userData: any;
  firstname: string;
  lastname: string;
  fullname: string;
  currentRole: any;
  profilePicture: string;
  public insiderDetails: any;
  public email: string;
  @Input() manageDropDown = false;
  constructor(
    private sessionStorageService: SessionStorageService,
    private routerService: RouterService,
    private dataService: DataService
  ) {
    this.showSwitchRole = false;
  }

  ngOnInit() {
    //console.log("==================================")
    this.showProfileDropdown = false;
    this.isSubscriptionAlive = true;
    this.insiderDetails = {};
    this.email = '';
    this.sessionStorageService.currentAssignmentDetails.pipe(takeWhile(() => this.isSubscriptionAlive)).subscribe((cad) => {
      if (cad) {
        this.isLoggedIn = true;
      } else {
        this.isLoggedIn = false;
      }
      this.userData = cad;
      this.userDetailsSubscription = this.sessionStorageService.userDetails.subscribe((result) => {
        if (result) {
          this.email = result.Email;
          this.profilePicture = 'assets/profiles/' + result.Email.split('@', 1) + '.jpg';
          this.fullname = result.FullName;
          this.firstname = result.FirstName;
          this.lastname = result.LastName;
          this.currentRole = this.userData.RoleName;
          if (result.UserAssignments && result.UserAssignments.length > 1) {
            this.showSwitchRole = true;
          }
        } else {
          this.firstname = '';
          this.lastname = '';
          this.currentRole = '';
          this.showSwitchRole = false;
        }
      });
    });
    if (this.currentRole === RoleEnum.INSIDER) {
      this.sessionStorageService.clientMasterListDetails.subscribe((data) => {
        if (data) {
          const insiderData: any[] = (data.kmpDetails && data.kmpDetails.InsiderDetails) ? data.kmpDetails.InsiderDetails : [];
          if (insiderData && insiderData.length && this.email) {
            insiderData.map((item: any) => {
              if (item.UserInformation && item.UserInformation.Email &&
                item.UserInformation.Email.toLowerCase() === this.email.toLowerCase()) {
                this.insiderDetails = { ...item };
                //console.log(this.insiderDetails);
                //added for dashboard dredirect
                if(this.currentRole === RoleEnum.INSIDER){
                  this.dataService.updateInsiderData(this.insiderDetails);
                }
              }
            });
          }
        }
      });
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.manageDropDown.currentValue === true) {
      this.showProfileDropdown = false;
    }
  }

  public navigateToSwitchRole(): void {
    this.dataService.openSwitchRole(true);
    // this.routerService.RedirectLogin();
  }

  public isProfileUpdateVisible(): boolean {
    if (this.currentRole === RoleEnum.DesignatedPerson || this.currentRole === RoleEnum.INSIDER) {
      return true;
    }
    return false;
  }

  gotoProfile() {
    this.dataService.updateDesignatedPersonData(null);
    if (this.currentRole === RoleEnum.DesignatedPerson) {
      this.routerService.RedirectToDesignatedPerson();
    }
    if (this.currentRole === RoleEnum.INSIDER) {
      this.dataService.updateInsiderData(this.insiderDetails);
      this.routerService.RedirectToInsiderDetails();
    }
  }

  logOut(){
    this.routerService.RedirectToLogOut();
    // window.location.reload();
  }

}
