import { Component, OnInit, ViewEncapsulation, Input, OnDestroy, Output, EventEmitter, OnChanges, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import { SessionStorageService } from 'src/app/shared/services/session-storage.service';
import { RouterService } from '../../../shared/services/router.service';
import { DataService } from 'src/app/shared/services/data.service';
import { LocalStorageService } from 'src/app/shared/services/local-storage.service';
// import { Subscription } from 'rxjs';
import * as _ from 'lodash';
import { ToastMessageService } from 'src/app/shared/services';
import { IWorkflowMaster } from 'src/app/shared/models/workflowmaster';
import { WorkflowManagerService } from 'src/app/zibble/cosec/workflow-manager/service/workflow-manager.service';
import { HttpErrorResponse } from '@angular/common/http';
import { takeWhile } from 'rxjs/operators';
import { RoleEnum } from 'src/app/shared/constants/enum';
import { truncateSync } from 'fs';
// import { KmpMainComponent } from 'src/app/zibble/cosec/client-onboarding/component/KMP/kmp-main/kmp-main.component';


@Component({
  selector: 'app-section-header',
  templateUrl: './section-header.component.html',
  styleUrls: ['./section-header.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class SectionHeaderComponent implements OnInit, OnDestroy, OnChanges {

  unreadNotificationCount: number;
  selectedAction: any;
  issueCount: number;
  displayCommentPopup = false;
  public loading = false;
  comments: string;
  hideUploadDownloadButtons = false;
  @Input() moduleName: string;
  @Input() offering: string;
  @Input() companyName: string;
  @Input() showDeleteButton = false;
  @Input() showCancelButton = false;
  @Input() showEditButton = false;
  @Input() showSearchButton = false;
  @Input() showFilterButton = false;
  @Input() showUploadButton = false;
  @Input() showDownloadButton = false;
  @Input() showIssueButton = false;
  @Input() showSaveButton = false;
  @Input() showSubmitButton = false;
  @Input() showBackButton = false;
  @Input() currentAction: string;
  @Input() actionDropdown: any;
  @Input() actionData: IWorkflowMaster;
  @Output() selectedBarAction = new EventEmitter<any>();
  @Output() selectedDropdownAction = new EventEmitter<any>();
  @Output() uploadedFileData = new EventEmitter<any>();
  @Output() onChangeAction = new  EventEmitter<any>();
  @Output() workflowData = new  EventEmitter<any>();
  @Input() showWorkflowComments = false;
  @Input() showAuditTrails = false;
  @Input() workflowComments: any;
  @Input() auditTrailsComments: any;
  @Input() kmpTabName: any;

  ReqObjectStatus="";
  clickDelete = true;
  clickCancel = false;
  clickEdit = true;
  clickSearch = true;
  clickFilter = true;
  clickUpload = true;
  clickDownload = true;
  clickIssue = true;
  clickSave = false;
  clickSubmit = true;
  public displayWorkflowCommentPopup = false;
  public displayAuditTrailsPopup = false;

  constructor(private sessionStorageService: SessionStorageService, private routerService: RouterService,
    private dataService: DataService, private localStorageService: LocalStorageService,
    private toastMessageService: ToastMessageService,
    private workflowManagerService: WorkflowManagerService,
    private cd: ChangeDetectorRef,
    // private kmpMainComponent: KmpMainComponent
  ) {
    this.actionDropdown = []; // ['Save', 'Cancel'];
    this.selectedAction = {};
  }

  ngOnInit() {
    this.sessionStorageService.currentAssignmentDetails.pipe(takeWhile(() => true)).subscribe((result) => {
      if (result) {
        if(result.RoleName === RoleEnum.Auditor || result.RoleName === RoleEnum.AuditCommitteeMember) {
          this.hideUploadDownloadButtons = true;
        }
      }});
    this.performEnablement('');
    this.issueCount = 0;
    const submitActionDrpdwn = [];
    if (this.actionDropdown.length > 0) {
      this.actionDropdown.forEach(x => {
        submitActionDrpdwn.push(
          { label: x, value: x }
        );
      });
      this.actionDropdown = submitActionDrpdwn;
    }
    if (!this.workflowComments) {
      this.workflowComments = [];
    }
    if(!this.auditTrailsComments){
      this.auditTrailsComments = [];
    }

  }

  ngOnDestroy() {
  }

  goBack() {
    this.routerService.redirectBack();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.workflowComments) {
      if (typeof changes.workflowComments === 'string') {
        this.workflowComments = JSON.parse(changes.workflowComments);
      }
      this.workflowComments.reverse();
    }
    if (changes.actionData && changes.actionData.currentValue) {
      const workflowReqObject = changes.actionData.currentValue;
      if (workflowReqObject.ClientId && workflowReqObject.CurrentRole &&
        workflowReqObject.CurrentStatus && workflowReqObject.ModuleName) {
        this.getWorkflowActions(workflowReqObject);
      }
    }
  }

  private getWorkflowActions(workflowReqObject) {
    // this.clickSubmit = undefined;
    debugger;
    this.loading = true;
    this.ReqObjectStatus=workflowReqObject.CurrentStatus;
    this.workflowManagerService.getWorkflowActions(workflowReqObject).subscribe((result) => {
      this.actionDropdown = [];
      if (result && result.length > 0) {
        result.forEach(action => {
          this.actionDropdown.push({ label: action.ActionName, value: action });
        });
        // this.clickSubmit = true;
        // this.cd.markForCheck();
        this.actionDropdown = _.uniqBy(this.actionDropdown, 'label');
        this.workflowData.emit(result)
      }
      this.loading = false;
    }, (err: HttpErrorResponse) => {
      if (err.status === 400 || err.status === 500) {
        this.toastMessageService.showErrorMessage('Unable to load workflow actions. Please contact administrator');
      } else {
        this.toastMessageService.showErrorMessage('Unable to load workflow actions. Please try again.');
      }
      this.loading = false;
    });
  }

  showCommentPopup() {
    if (this.selectedAction.ActionName) {
      this.comments = '';
      this.displayCommentPopup = true;
    }
    else {
      this.toastMessageService.showErrorMessage("Action is Required");
    }

  }

  submitBarAction(lBarAction: string) {
    this.displayCommentPopup = false;
    if (lBarAction === 'submit') {
      if (this.selectedAction !== undefined && this.selectedAction !== null && Object.keys(this.selectedAction).length) {
        this.selectedAction.Comments = this.comments;
        this.selectedDropdownAction.emit(this.selectedAction);
        this.currentAction = lBarAction;
      }
      else {
        //this.toastMessageService.showErrorMessage("Please select Action before submitting");
      }
      //else {
      //  this.currentAction = 'cancel';
      //}
      this.performEnablement('');
    } else {
      this.currentAction = lBarAction;
    }
    this.selectedBarAction.emit(this.currentAction);
    if (lBarAction !== 'save' && lBarAction !== 'process') {
      this.performEnablement(lBarAction);
    }
    if (lBarAction === 'process') {
      this.disableAll();
    }
  }

  getdateFromUTC(date) {
    return date + 'UTC';
  }

  performEnablement(lBarAction: string) {
    debugger;
    if(this.ReqObjectStatus=="Rejected" || this.ReqObjectStatus=="Cancelled"){
    this.clickSubmit = false;
    } 
    switch (lBarAction) {
      case '': {
        this.clickDelete = true;
        this.clickCancel = false;
        this.clickEdit = true;
        this.clickSearch = true;
        this.clickFilter = true;
        this.clickUpload = true;
        this.clickDownload = true;
        this.clickIssue = true;
        this.clickSave = false;
        this.clickSubmit = false;
        break;
      }
      case 'add': {
        this.clickDelete = false;
        this.clickCancel = true;
        this.clickEdit = false;
        this.clickSearch = false;
        this.clickFilter = false;
        this.clickUpload = false;
        this.clickDownload = false;
        this.clickIssue = false;
        this.clickSave = true;
        this.clickSubmit = false;
        // if (this.actionDropdown.length > 0) {
        //   this.clickSubmit = true;
        // } else {
        //   this.clickSubmit = false;
        // }
        break;
      }
      case 'delete': {
        this.clickDelete = true;
        this.clickCancel = false;
        this.clickEdit = true;
        this.clickSearch = true;
        this.clickFilter = true;
        this.clickUpload = true;
        this.clickDownload = true;
        this.clickIssue = true;
        this.clickSave = false;
        this.clickSubmit = false;
        break;
      }
      case 'cancel': {
        this.clickDelete = true;
        this.clickCancel = false;
        this.clickEdit = true;
        this.clickSearch = true;
        this.clickFilter = true;
        this.clickUpload = true;
        this.clickDownload = true;
        this.clickIssue = true;
        this.clickSave = false;
        this.clickSubmit = false;
        break;
      }
      case 'edit': {
        this.clickDelete = false;
        this.clickCancel = true;
        this.clickEdit = false;
        this.clickSearch = false;
        this.clickFilter = false;
        this.clickUpload = false;
        this.clickDownload = false;
        this.clickIssue = false;
        this.clickSave = true;
        if (this.actionDropdown.length > 0) {
          this.clickSubmit = true;
        } else {
          this.clickSubmit = false;
        }
        break;
      }
      case 'search': {
        this.clickDelete = true;
        this.clickCancel = false;
        this.clickEdit = true;
        this.clickSearch = true;
        this.clickFilter = true;
        this.clickUpload = true;
        this.clickDownload = true;
        this.clickIssue = true;
        this.clickSave = false;
        this.clickSubmit = false;
        break;
      }
      case 'filter': {
        this.clickDelete = true;
        this.clickCancel = false;
        this.clickEdit = true;
        this.clickSearch = true;
        this.clickFilter = true;
        this.clickUpload = true;
        this.clickDownload = true;
        this.clickIssue = true;
        this.clickSave = false;
        this.clickSubmit = false;
        break;
      }
      case 'upload': {
        this.clickDelete = true;
        this.clickCancel = false;
        this.clickEdit = true;
        this.clickSearch = true;
        this.clickFilter = true;
        this.clickUpload = true;
        this.clickDownload = true;
        this.clickIssue = true;
        this.clickSave = false;
        this.clickSubmit = false;
        break;
      }
      case 'download': {
        this.clickDelete = true;
        this.clickCancel = false;
        this.clickEdit = true;
        this.clickSearch = true;
        this.clickFilter = true;
        this.clickUpload = true;
        this.clickDownload = true;
        this.clickIssue = true;
        this.clickSave = false;
        this.clickSubmit = false;
        break;
      }
      case 'issue': {
        this.clickDelete = true;
        this.clickCancel = false;
        this.clickEdit = true;
        this.clickSearch = true;
        this.clickFilter = true;
        this.clickUpload = true;
        this.clickDownload = true;
        this.clickIssue = true;
        this.clickSave = false;
        this.clickSubmit = false;
        break;
      }
      case 'save': {
        this.clickDelete = true;
        this.clickCancel = false;
        this.clickEdit = true;
        this.clickSearch = true;
        this.clickFilter = true;
        this.clickUpload = true;
        this.clickDownload = true;
        this.clickIssue = true;
        this.clickSave = false;
        if (this.actionDropdown.length > 0) {
          this.clickSubmit = true;
        } else {
          this.clickSubmit = false;
        }
        break;
      }
      case 'submit': {
        this.clickDelete = true;
        this.clickCancel = true;
        this.clickEdit = true;
        this.clickSearch = true;
        this.clickFilter = true;
        this.clickUpload = true;
        this.clickDownload = true;
        this.clickIssue = true;
        this.clickSave = false;
        this.clickSubmit = true;
        break;
      }
      case 'disableAll': {
        this.clickDelete = false;
        this.clickCancel = true;
        this.clickEdit = false;
        this.clickSearch = false;
        this.clickFilter = false;
        this.clickUpload = false;
        this.clickDownload = false;
        this.clickIssue = true;
        this.clickSave = false;
        this.clickSubmit = false;
        break;
      }
      case 'disableAll': {
        this.clickDelete = false;
        this.clickCancel = true;
        this.clickEdit = false;
        this.clickSearch = false;
        this.clickFilter = false;
        this.clickUpload = false;
        this.clickDownload = false;
        this.clickIssue = true;
        this.clickSave = false;
        this.clickSubmit = false;
        break;
      }
      case 'specialCancel': {
        this.clickCancel = false;
        break;
      }
      case 'specialCancel': {
        this.clickCancel = false;
        break;
      }
    }
  }

  disableAll() {
    this.clickDelete = false;
    this.clickCancel = false;
    this.clickEdit = false;
    this.clickSearch = false;
    this.clickFilter = false;
    this.clickUpload = false;
    this.clickDownload = false;
    this.clickIssue = false;
    this.clickSave = false;
    this.clickSubmit = false;
  }

  showNotifications() {

  }

  showWorkflowCommentPopup() {
    this.displayWorkflowCommentPopup = true;
  }

  showAuditTrailsPopup() {
    this.displayAuditTrailsPopup = true;
  }

  uploadTemplate($event, template) {
    const uploadedData = {
      event: $event,
      template: template
    }
    this.uploadedFileData.emit(uploadedData);
    setTimeout(() => {
      template.clear();
    }, 500);

  }

}
