import { Component, OnInit, OnDestroy, ViewEncapsulation, Input, EventEmitter, Output } from '@angular/core';
import { takeWhile } from 'rxjs/operators';
import { MessagesModule } from 'primeng/messages';
import { LoginService } from '../../services/login.service';
import { Login } from '../../../shared/models/login';
import { IToken } from '../../../shared/models/token';
import { IClient } from '../../../shared/models/client';
import { IRole } from '../../../shared/models/role';
import { SessionStorageService } from '../../../shared/services/session-storage.service';
import { RouterService } from '../../../shared/services/router.service';
import { ResponseMessages } from '../../../shared/constants/response-messages';
import { LocalStorageService } from '../../../shared/services/local-storage.service';
import { Md5HashService } from '../../../shared/services/md5-hash.service';
import { ToastMessageService } from '../../../shared/services/toast-message.service';
import { IUserAssignment } from 'src/app/shared/models/userassignment';
import { OfferingEnum, RoleEnum } from '../../../shared/constants/enum';
import { environment } from 'src/environments/environment';
import * as _ from 'lodash';
import { Token } from '@angular/compiler/src/ml_parser/lexer';
import { DataService } from 'src/app/shared/services';
import { HttpHeaders } from '@angular/common/http';
import { EncryptDecryptService } from 'src/app/shared/services/encrypt-decrypt.service';
import * as CryptoJS from 'crypto-js';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {

  public loginDetails: Login = {} as Login;
  @Input() isOpenInPopup: boolean;
  @Output() closeModel = new EventEmitter<any>();
  public messages: MessagesModule[] = [];
  public errorMessage: string;
  public loginCount = 0;
  public isDialogVisible: boolean = true;
  public defaultPasswordFieldType = 'password';
  public passwordFieldType = true;
  public progressBar = false;
  public localPassword: string;
  // public companyList: Array<any>;
  // public roleList: Array<any>;
  public companyList: IClient[] = [];
  public roleList: IRole[] = [];
  public selectedCompany: IClient;
  public selectedRole: IRole;
  public userDetailsReceived = false;
  public localToken: IToken = {} as IToken;
  public continueLogin = false;
  public profilePicture: string;
  private isProcessingSwitchRole: boolean;
  private isUserLoginProgress: boolean;
  public displayTermsAndPolicy: boolean;
  public termsAndCondition: any;
  private companyInfo: any;
  private key = CryptoJS.enc.Utf8.parse(environment.cryptoEncrytionKey); // Must match the key used in .NET
  private ivLength = 16; // AES block size is 16 bytes for CBC mode

  constructor(
    private loginService: LoginService,
    private sessionStorageService: SessionStorageService,
    private routerService: RouterService,
    private localStorageService: LocalStorageService,
    private md5HashService: Md5HashService,
    private encryptService: EncryptDecryptService,
    private toastMessageService: ToastMessageService,
    private dataService: DataService
  ) {
    this.isProcessingSwitchRole = false;
    this.isUserLoginProgress = false;
    this.displayTermsAndPolicy = false;
  }

  ngOnInit() {
    // localStorage.clear();
    // this.sessionStorageService.flushOnLogout();
    this.loginDetails.SkipConcurrentSession=false;
    this.sessionStorageService.userAuthDetails.subscribe((result) => {
      if (result) {
        if (!this.isUserLoginProgress) {
          this.isProcessingSwitchRole = true;
          this.isUserLoginProgress = true;
          this.localToken = result;
          // this.localToken = this.localStorageService.userAuthDetails;
          // this.roleList[0] = this.localStorageService.selectedRole;
          // this.companyList = this.localStorageService.selectedCompany;
          // this.selectedCompany = this.localStorageService.selectedCompany;
          this.processUserAuthenticationInfo();
        }
      } else {
        if (!this.isProcessingSwitchRole) {
          this.initiateLogin();
          this.isUserLoginProgress = true;
        }
      }
    });
  }

  private initiateLogin() {
    localStorage.clear();
    if (environment.production && environment.enableWindowsAuthentication) {
      // Don't remove below comment or the function below. This has to be used for production deployment if Windows Authentication is true
      this.loginDetails.IsWindows = true;
      this.autoLogin();
    } else {
      this.loginDetails.IsWindows = false;
    }
  }
  goBack(){
    this.loginDetails.Email = '';
    this.localPassword = '';
    this.errorMessage = '';
    // this.isDialogVisible = false;
    this.routerService.RedirectLogin();
  }
  ngOnDestroy() {
  }

  public autoLogin() {
    this.progressBar = true;
    let isAlive = true;

    this.loginDetails.Email = '';
    this.loginDetails.Password = '';
    this.loginDetails.IsWindows = true;
    this.loginService.windowslogin().pipe(takeWhile(() => isAlive)).subscribe((result: IToken) => {
      this.progressBar = false;
      if (result != null && result.ResponseMessage === ResponseMessages.success) {
        this.localToken = result;
        isAlive = false;
        this.processUserAuthenticationInfo();
      } else {
        this.loginDetails.IsWindows = false;
        // this.toastMessageService.showInfoMessage(result.ResponseMessage);
        this.toastMessageService.showInfoMessage('Windows automatic authentication failed, please login using credentials');
      }
    },
      error => {
        this.loginDetails.IsWindows = false;
        this.progressBar = false;
        if (error.error !== undefined && error.error !== null) {
          this.toastMessageService.showErrorMessage(error.error.text);
        } else {
          this.toastMessageService.showErrorMessage(error.statusText);
        }
      }
    );
  }

  public processUserAuthenticationInfo() {
    this.profilePicture = 'assets/profiles/' + this.localToken.Email.split('@', 1) + '.jpg';
    this.companyList = [];
    _.forEach(this.localToken.UserDetails.UserAssignments, (lCompany: any) => {
      let companyNameCount : number = JSON.parse(lCompany.OwnershipTags).Company.length;
      const companyName: string = JSON.parse(lCompany.OwnershipTags) ? JSON.parse(lCompany.OwnershipTags).Company[companyNameCount-1] : '';
      const lCName: any = _.find(this.companyList, { ClientName: companyName });
      if (lCName === undefined) {
        const objClientMaster: IClient = {} as IClient;
        objClientMaster.CompanyName = lCompany.Client.ClientName;
        objClientMaster.ClientName = companyName;
        this.companyList.push(objClientMaster);
      }
    });

    

    if (this.companyList.length > 0) {
      this.selectedCompany = this.companyList[0];
      this.roleList = [];
      _.forEach(this.localToken.UserDetails.UserAssignments, (lCompany: any) => {
        let companyNameCount : number = JSON.parse(lCompany.OwnershipTags).Company.length;
        const companyName: string = JSON.parse(lCompany.OwnershipTags) ? JSON.parse(lCompany.OwnershipTags).Company[companyNameCount-1] : '';
        if (companyName === this.companyList[0].ClientName) {
          const lRole: any = _.find(this.roleList, { RoleName: lCompany.RoleName });
          if (lRole === undefined) {
            const objRoleMaster: IRole = {} as IRole;
            objRoleMaster.RoleName = lCompany.RoleName;
            this.roleList.push(objRoleMaster);
          }
        }
      });
      if (this.roleList.length > 0) {
        this.selectedRole = this.roleList[0];
      }
    }

    // if(this.localStorageService.selectedRole){
    //   this.roleList=[]
    //   this.roleList[0] = this.localStorageService.selectedRole;
    // }


    if (this.companyList.length == 1 && this.roleList.length == 1) {
      this.sessionStorageService.setCountryListDetails(null);
      this.sessionStorageService.setModuleList(null);
      this.sessionStorageService.setCompanyTypes(null);
      this.sessionStorageService.setCompanyEssentials(null);
      this.sessionStorageService.setMeetingEssentials(null);
      this.sessionStorageService.setTransactions(null);
      this.sessionStorageService.setFieldsInventory(null);
      _.forEach(this.localToken.UserDetails.UserAssignments, (lCompany: any) => {
        let companyNameCount : number = JSON.parse(lCompany.OwnershipTags).Company.length;
        if (lCompany.RoleName === this.selectedRole.RoleName &&
          JSON.parse(lCompany.OwnershipTags).Company[companyNameCount-1] === this.selectedCompany.ClientName) {
          // this.redirectToDashboard(lCompany);
          this.companyInfo = lCompany;
        }
      });
      this.getAllTermsAndConditions(this.companyInfo);
    } else {
      this.userDetailsReceived = true;
    }

  }

  public authenticate() {
    this.loginCount += 1;
    if(this.loginCount >= 6){
      this.errorMessage = "You have reached the maximum limit. Please try after sometime";
      return
    }
    this.progressBar = true;
    this.messages = [];
    this.loginDetails.Password = this.encryptService.encryptPassword(this.localPassword, this.encryptService.decrypt(environment.cryptoEncrytionKey));
    this.loginDetails.IsWindows = false;
    let isAlive = true;
    this.loginService.formslogin(this.loginDetails).pipe(takeWhile(() => isAlive)).subscribe((result: IToken) => {
      this.progressBar = false;
      if (result != null && result.ResponseMessage === ResponseMessages.success) {
        const decryptResponse:any = this.decryptResponse(result.Token)
        this.loginDetails.SkipConcurrentSession=false;
        this.localToken = JSON.parse(decryptResponse);
		    this.localStorageService.userAuthDetails = this.localToken;
        // userAuthDetails
        // sessionStorage.setItem('Token',this.localToken);
        isAlive = false;
        this.processUserAuthenticationInfo();
      } else if (result != null) {
        switch (result.ResponseMessage) {
          case ResponseMessages.invalidUserNameOrPassword:
            this.errorMessage = 'Invalid login credentials.';
            break;
          case ResponseMessages.roleNotAssignedToUser:
            this.errorMessage = 'User is not assigned to any role.';
            break;
          case ResponseMessages.userDoesNotExists:
            this.errorMessage = 'User does not exist.';
            break;
          case ResponseMessages.userNameAndPasswordAreRequired:
            this.errorMessage = 'Username and Password are required.';
            break;
          case ResponseMessages.contractEnd:
            this.errorMessage = 'Contract Expired.';
            break;
          case ResponseMessages.assignmentsMissing:
            this.errorMessage = 'User Assignments missing.';
            break;
          case ResponseMessages.invalidUser:
            this.errorMessage = 'Invalid User';
            break;
          case ResponseMessages.concurrentSessionFound:
            this.errorMessage = 'Concurrent Session Found';
            break;
            
          default:
        }
      } else {
        this.errorMessage = 'Something went wrong. Please try after sometime.';
      }
    });
  }

  concurrentSession(){
    this.loginDetails.SkipConcurrentSession=true;
    this.authenticate();
  }

  // setCookie(name: string, value: string, days: number) {
  //   const date = new Date();
  //   date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
  //   const expires = `expires=${date.toUTCString()}`;
  //   document.cookie = `${name}=${value}; ${expires}; path=/`;
  // }
  

  public changeRoleForCompany() {
    this.roleList = [];
    _.forEach(this.localToken.UserDetails.UserAssignments, (lCompany: any) => {
      const companyName: string = JSON.parse(lCompany.OwnershipTags) ? JSON.parse(lCompany.OwnershipTags).Company[0] : '';
      if (companyName === this.selectedCompany.ClientName) {
        const lRole: any = _.find(this.roleList, { RoleName: lCompany.RoleName });
        if (lRole === undefined) {
          const objRoleMaster: IRole = {} as IRole;
          objRoleMaster.RoleName = lCompany.RoleName;
          this.roleList.push(objRoleMaster);
        }
      }
    });
    this.selectedRole = this.roleList[0];
  }

  public selectCompanyAndRole() {
    if (this.selectedCompany === undefined || this.selectedCompany === null) {
      this.toastMessageService.showErrorMessage('No Company Assigned. Please check with administrator');
    } else { this.continueLogin = true; }

    if (this.selectedRole === undefined || this.selectedRole === null) {
      this.toastMessageService.showErrorMessage('No Role Assigned. Please check with administrator');
    } else { this.continueLogin = true; }

    if (this.continueLogin === true) {
      
      this.closeModel.emit(true);
      this.localStorageService.selectedRole = this.selectedRole;
      this.localStorageService.selectedCompany = this.selectedCompany;
      this.sessionStorageService.setCountryListDetails(null);
      this.sessionStorageService.setModuleList(null);
      this.sessionStorageService.setCompanyTypes(null);
      this.sessionStorageService.setCompanyEssentials(null);
      this.sessionStorageService.setMeetingEssentials(null);
      this.sessionStorageService.setTransactions(null);
      this.sessionStorageService.setFieldsInventory(null);
      _.forEach(this.localToken.UserDetails.UserAssignments, (lCompany: any) => {
        let companyNameCount : number = JSON.parse(lCompany.OwnershipTags).Company.length;
        if (lCompany.RoleName === this.selectedRole.RoleName &&
          JSON.parse(lCompany.OwnershipTags).Company[companyNameCount-1] === this.selectedCompany.ClientName) {
          this.companyInfo = lCompany;
        }
      });
      this.getAllTermsAndConditions(this.companyInfo);
    }
  }

  public getAllTermsAndConditions(lCompany) {
    if (!this.isOpenInPopup) {
      this.loginService.getTermsAndCondition({ ClientId: lCompany.ClientId }, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.localToken.AuthToken}`,
          //UserEmail: this.localStorageService.userDetails.Email
        })
      }).subscribe(data => {
        if (data && data[0].TermsAndConditions) {
          const termsNConditionsList = data[0].TermsAndConditions;
          const ownerTag = JSON.parse(lCompany.OwnershipTags);
          if (lCompany.OwnershipTags) {
            if (ownerTag.TnCversion !== termsNConditionsList[termsNConditionsList.length - 1].Version) {
              // this.sessionStorageService.setUserAuthDetails(null);
              this.termsAndCondition = termsNConditionsList[termsNConditionsList.length - 1];
              this.displayTermsAndPolicy = true;
            } else {
              data[0].TermsAndConditions.forEach(element => {
                if (element.Version === ownerTag.TnCversion) {
                  this.dataService.updateTermsAndConditions(element);
                }
              });
              this.sessionStorageService.setUserAuthDetails(this.localToken);
              this.sessionStorageService.setCurrentAssignmentDetails(this.companyInfo);
              this.sessionStorageService.setCurrentCompany(this.companyInfo.Client.ClientName);
              this.sessionStorageService.setCompanyModuleList(null);
              this.sessionStorageService.setUserDetails(this.localToken.UserDetails);
              this.sessionStorageService.setUserAssignmentsDetails(this.localToken.UserDetails.UserAssignments);
              this.redirectToDashboard(lCompany);
            }
          }
        } else {
          this.sessionStorageService.setUserAuthDetails(this.localToken);
          this.sessionStorageService.setCurrentAssignmentDetails(this.companyInfo);
          this.sessionStorageService.setCurrentCompany(this.companyInfo.Client.ClientName);
          this.sessionStorageService.setCompanyModuleList(null);
          this.sessionStorageService.setUserDetails(this.localToken.UserDetails);
          this.sessionStorageService.setUserAssignmentsDetails(this.localToken.UserDetails.UserAssignments);
          this.redirectToDashboard(lCompany);
        }
      }, (error) => {
        if (error) {
          this.sessionStorageService.setUserAuthDetails(this.localToken);
          this.sessionStorageService.setCurrentAssignmentDetails(this.companyInfo);
          this.sessionStorageService.setCurrentCompany(this.companyInfo.Client.ClientName);
          this.sessionStorageService.setCompanyModuleList(null);
          this.sessionStorageService.setUserDetails(this.localToken.UserDetails);
          this.sessionStorageService.setUserAssignmentsDetails(this.localToken.UserDetails.UserAssignments);
          this.redirectToDashboard(lCompany);
        }
      });
    } else {
      this.sessionStorageService.setUserAuthDetails(this.localToken);
      this.sessionStorageService.setCurrentAssignmentDetails(this.companyInfo);
      this.sessionStorageService.setCurrentCompany(this.companyInfo.Client.ClientName);
      this.sessionStorageService.setCompanyModuleList(null);
      this.sessionStorageService.setUserDetails(this.localToken.UserDetails);
      this.sessionStorageService.setUserAssignmentsDetails(this.localToken.UserDetails.UserAssignments);
      this.redirectToDashboard(lCompany);
    }

  }

  public onclickTermsButton(value) {
    if (value === 'disagree') {
      this.loginDetails.IsWindows = false;
      this.userDetailsReceived = false;
      this.displayTermsAndPolicy = false;
      this.continueLogin = false;
      this.localPassword = '';
      this.loginDetails.Email = '';
      this.sessionStorageService.setUserAuthDetails(null);
    } else if (value === 'agree') {
      this.sessionStorageService.setUserAuthDetails(this.localToken);
      this.sessionStorageService.setCurrentAssignmentDetails(this.companyInfo);
      this.sessionStorageService.setCurrentCompany(this.companyInfo.Client.ClientName);
      this.sessionStorageService.setCompanyModuleList(null);
      this.sessionStorageService.setUserDetails(this.localToken.UserDetails);
      this.sessionStorageService.setUserAssignmentsDetails(this.localToken.UserDetails.UserAssignments);
      this.dataService.updateTermsAndConditions(this.termsAndCondition);
      this.displayTermsAndPolicy = false;
      const ownerTag = JSON.parse(this.companyInfo.OwnershipTags);
      ownerTag['TnCversion'] = this.termsAndCondition.Version;
      const obj = {
        ClientId: this.companyInfo.ClientId,
        OwnershipTags: JSON.stringify(ownerTag),
        UserTags: '{\"UserEmail\": [\"' + this.loginDetails.Email + '\"]}'
      };
      this.loginService.updateUserversion(obj).subscribe();
      this.redirectToDashboard(this.companyInfo);
    }
  }

  public forgotPassword() {
    this.routerService.RedirectForgotPassword();
  }
  onPasswordChange(){
    this.errorMessage = ''
  }
  public changePasswordFieldType() {
    if (this.passwordFieldType) {
      this.defaultPasswordFieldType = 'text';
      this.passwordFieldType = false;
    } else {
      this.defaultPasswordFieldType = 'password';
      this.passwordFieldType = true;
    }
  }

  private redirectToDashboard(assignment: IUserAssignment) {
    // assignment.OwnershipTags = JSON.parse(assignment.OwnershipTags);
    if (assignment.RoleName === RoleEnum.Admin && (assignment.OwnershipTags === null)) {
      this.routerService.RedirectToMasterAdminDashboard();
    } else {
      this.sessionStorageService.setOfferingName(OfferingEnum.COSEC);
      this.CoSecRedirection(assignment);
    }
  }

  private CoSecRedirection(assignment: IUserAssignment) {
    // if (assignment.RoleName === RoleEnum.Admin) {
    this.routerService.RedirectToCoSecAdminDashboard();
    // }
  }
  onHideTermsAndConditionPopup() {
    this.continueLogin = false;
  }

  
    decryptResponse(encryptedResponse: string): string {
      // Decode Base64-encoded encrypted response
      const encryptedData = CryptoJS.enc.Base64.parse(encryptedResponse);
      
      // Split the IV from the rest of the message
      const iv = CryptoJS.lib.WordArray.create(encryptedData.words.slice(0, this.ivLength / 4)); 
      const encrypted = CryptoJS.lib.WordArray.create(encryptedData.words.slice(this.ivLength / 4));
  
      // Decrypt using the same key and IV
      const decrypted = CryptoJS.AES.decrypt(
        { ciphertext: encrypted },
        this.key,
        { iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 }
      );
  
      // Return the decrypted message as a string
      return decrypted.toString(CryptoJS.enc.Utf8);
    }
}
