import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpService } from 'src/app/shared/services/http.service';
import { EnvService } from 'src/app/services/env.service';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class CodesAndPoliciesService {

  constructor(
    private http: HttpService,
    private env: EnvService
  ) { }

  private readonly apiprojectUrl = this.env.baseUrl + environment.services.codesAndPolicies.baseUrl;

  getAllCodesPolicies(data) {
    const url = environment.services.codesAndPolicies.api.getAllCodesPolicies;
    return this.http.post<any>(this.apiprojectUrl, url, data);
  }

  updateCodePolicyDetails(data) {
    const url = environment.services.codesAndPolicies.api.updateCodePolicyDetails;
    data.AttachPolicies = this.encrypteData(data.AttachPolicies);
    data.KeyConsideration = this.encrypteData(data.KeyConsideration);
    return this.http.post<any>(this.apiprojectUrl, url, data);
  }

  getAllKeyConsiderationsForPolicy(data) {
    const url = environment.services.codesAndPolicies.api.getAllKeyConsiderationsForPolicy;
    return this.http.post<any>(this.apiprojectUrl, url, data);
  }
  encrypteData(data){
    const secretKeyBase64 = environment.secretKey;
    // Decode the Base64 secret key into bytes
    const secretKey = CryptoJS.enc.Base64.parse(secretKeyBase64);
    // Generate a random IV (initialization vector)
    const iv = CryptoJS.lib.WordArray.random(16);
    // Encrypt the documentID using the secretKey and random IV
    const encrypted = CryptoJS.AES.encrypt(data.toString(), secretKey, {
      iv: iv, // Provide the IV
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    });
    // Combine the IV and encrypted data
    return iv.concat(encrypted.ciphertext).toString(CryptoJS.enc.Base64);
  }
h

}
