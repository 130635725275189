import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from '../../../../../node_modules/@angular/router';
import { finalize } from '../../../../../node_modules/rxjs/operators';
import { LocationStrategy } from '@angular/common';
import { Md5HashService } from '../../../shared/services/md5-hash.service';
import { RouterService } from '../../../shared/services/router.service';
import { ToastMessageService } from '../../../shared/services/toast-message.service';
import { LoginService } from '../../services/login.service';
import { ProgressBarService } from '../../services/progress-bar.service';
import { EncryptDecryptService } from 'src/app/shared/services/encrypt-decrypt.service';
import { environment } from 'src/environments/environment';
import { ToolNameEnum } from 'src/app/shared/constants/enum';
import * as _ from 'lodash';
@Component({
  selector: 'app-reset-password',
  styleUrls: ['./reset-password.component.scss'],
  templateUrl: './reset-password.component.html'
})
export class ResetPasswordComponent implements OnInit {
  public invalidErrorFlag: boolean;
  public frmAuthenticate: any;
  public frmResetPassword: any;
  public isEnabledOtp: boolean;
  public loader: boolean;
  public setResetCaseNo: number;
  public _successMessagePopUp: boolean;
  public subscriptions: any[];
  constructor(
    private readonly route: ActivatedRoute,
    private readonly md5HashService: Md5HashService,
    private readonly routerService: RouterService,
    private readonly loginService: LoginService,
    public progressBarService: ProgressBarService,
    public toastMessageService: ToastMessageService,
    public location: LocationStrategy,
    private encryptService: EncryptDecryptService,
    public router: Router) {
    history.pushState(null, null, window.location.href);
    window.onpopstate = function () {
      history.go(1);
    };
  }

  public cancelForgotPassword(): void {
    this.routerService.RedirectLogin();
  }

  public ngOnInit(): void {
    this._successMessagePopUp = false;
    this.subscriptions = [];
    this.frmAuthenticate = { Email: '', ResetPasswordCode: '', };
    this.frmResetPassword = { Id: '', Password: '', confirmPassword: '' };
    this.loader = false;
    this.isEnabledOtp = true;
    this.invalidErrorFlag = false;
    this.route.params.subscribe((result: any) => {
      const userEmail: string = result.userEmail;
      this.setResetCaseNo = Number(result.setResetCaseNo);
      if (this.setResetCaseNo.toString() === '3') {
        this.loginService.authUserDetailsByEmail({Email:userEmail}).subscribe((data) => {
          this.frmResetPassword.Id = data.Id
        },
          error => {
            this.toastMessageService.showErrorMessage('Error in getting userdetails');
          }
        );
      }

      if (userEmail !== undefined && userEmail !== null && userEmail !== '') {
        this.frmAuthenticate.Email = userEmail;
      }
    });
  }

  public otpvalidation(event): boolean | void {
    if (event.keyCode < 48 || event.keyCode > 57) {
      return false;
    }
  }

 public  resetUserPassword(): any {
    if (this.frmResetPassword.Password.trim() === this.frmResetPassword.confirmPassword.trim()) {
      this.progressBarService.toggle(true);
      this.loader = true;
      const userPassword = { ... this.frmResetPassword };
      userPassword.Password = this.encryptService.encryptPassword(this.frmResetPassword.Password, this.encryptService.decrypt(environment.cryptoEncrytionKey));
      userPassword.Email = this.frmAuthenticate.Email;
      userPassword.ResetPasswordCode = this.frmAuthenticate.ResetPasswordCode;
      userPassword.confirmPassword = this.encryptService.encryptPassword(this.frmResetPassword.confirmPassword, this.encryptService.decrypt(environment.cryptoEncrytionKey));      
      this.subscriptions.push(this.loginService.resetPassword(userPassword).subscribe((result:any) => {
        if (result && result.length) {
          this._successMessagePopUp = true;
           let mailData: any = {};
              mailData = {
                Subject: 'Password Reset Successfully',
                MailTo: JSON.stringify({ 'To': [this.frmAuthenticate.Email] }),
                MailTemplate: 'Reset-Password-Success',
                IsDeleted: false,
                ClientId: 0,
                MailData: {
                  URL: '/',
                  dateQuarter: '', Action: 'Reset Password',
                  ClientName: 'Password Reset Successfully', Comment: '',
                  ModuleName: 'Reset Password',
                  Toolname: ToolNameEnum.ToolName 
                },
              };
            const sendResult: boolean | any = this.loginService.sendEmail(mailData);
            if (sendResult) {
             console.log('Success');
            } else {
              console.log('Error');
            }
          setTimeout(() => {
            this._successMessagePopUp = false;
            this.loader = false;
            this.routerService.RedirectLogin();
          }, 4000);
        } else {
          this.loader = false;
          this.toastMessageService.showErrorMessage('Invalid reset code.');
          this._successMessagePopUp = false;
        }
      },
        error => {
          const decryptedError = environment.enforceApiDecryption ? this.encryptService.decrypt(error.error) : error;
          this.loader = false;
          this._successMessagePopUp = false;
          this.progressBarService.toggle(false);
          this.toastMessageService.showErrorMessage(error.error.Message.replace(/^P0001:\s*/, ''));
        }
      ));
    } 
  }

  public forgotPassword():any {
    this.routerService.RedirectForgotPassword();
  }
  public verifyCode():any {
    this.invalidErrorFlag = false;
    if (this.frmAuthenticate.ResetPasswordCode && this.frmAuthenticate.ResetPasswordCode.length === 6) {
      this.isEnabledOtp = false;
      this.progressBarService.toggle(true);
      this.loader = true;
      this.frmResetPassword.Id = '';
      this.subscriptions.push(this.loginService.verifyResetCode(this.frmAuthenticate).pipe(finalize(() => {
        this.progressBarService.toggle(false);
        this.loader = false;
      })).subscribe((result: any) => {
        this.loader = false;
        if (result && result.length) {
          this.invalidErrorFlag = false;
          this.frmResetPassword.Id = result[0].Id;
          this.isEnabledOtp = false;
        } else {
          // this.toastMessageService.showErrorMessage('Invalid reset code.');
          this.invalidErrorFlag = true;
          this.isEnabledOtp = true;
        }
      },
        error => {
          this.loader = false;
          this.isEnabledOtp = true;
          this.invalidErrorFlag = true;
        }
      ));
    }
  }

  public ngOnDestroy(): void {
    _.forEach(this.subscriptions, sub => sub.unsubscribe());
  }
}
