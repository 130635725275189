import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { RouterService } from '../../../shared/services/router.service';
import { SessionStorageService } from '../../../shared/services/session-storage.service';
import { ToastMessageService } from '../../../shared/services/toast-message.service';
import { LoginService } from '../../services/login.service';
import * as _ from 'lodash';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ReCaptcha2Component } from 'ngx-captcha';

// tslint:disable
@Component({
  selector: 'app-forgot-password',
  styleUrls: ['./forgot-password.component.scss'],
  templateUrl: './forgot-password.component.html'
})

export class ForgotPasswordComponent implements OnInit {
  @ViewChild('captchaElem') captchaElem: ReCaptcha2Component;
  protected aFormGroup: FormGroup;

  formGroup: FormGroup;
  public flag : boolean;
  public openEmailPopUp: boolean;
  public UserEmail: string;
  public forgotPassword = { 'Email': null };
  public showSentMessage: boolean ;
  public subscriptions:any[];
  public currentYear :any = new Date().getFullYear();
  public loader: boolean;
  public sitekey: '6LcDxmoqAAAAAHrsIexQukEg-Z8sMSSScatyPWq1';
  captchaResponse: string | null = null;
  type: string = 'image'; // or other appropriate value
  badge: string = 'bottomright'; // or other appropriate value
  captchaText: string;
  recaptchaResponse: boolean;

  public captchaIsLoaded = false;
  public captchaSuccess = false;
  public captchaIsExpired = false;

  public theme: 'light' | 'dark' = 'light';
  public size: 'compact' | 'normal' = 'normal';
  public lang = 'en';



  constructor(
    public fb: FormBuilder,
    private routerService: RouterService, 
    private loginService: LoginService, 
    public router: Router, 
    public toastMessageService: ToastMessageService,
    private formBuilder: FormBuilder
    ) { }

  public authenticate():any {
    this.loader = true;
    this.flag = false;
    let isAlive = true;
    this.subscriptions.push(this.loginService.authUserDetailsByEmail({Email:this.forgotPassword.Email}).pipe().subscribe((result:any) => {
      if (result) {
        isAlive = false;
            this.showSentMessage = true;
        //console.log(this.showSentMessage);
        this.loader = false;
      } else {
        this.loader = false;
        this.toastMessageService.showErrorMessage("The email or captcha you entered is incorrect.");
        this.flag = true;
      }
    },
      error => { 
        this.loader = false;
        this.toastMessageService.showErrorMessage("The email or captcha you entered is incorrect.");
      }
    ));
  }
  public cancelForgotPassword():any {
    this.routerService.RedirectLogin();
  }

  onCaptchaResolved(captchaResponse: string) {
    console.log('Captcha resolved with response:', captchaResponse);
    // You can send the captchaResponse to your backend for verification
  }


  public ngOnInit():any {

    this.aFormGroup = this.formBuilder.group({
      recaptcha: ['', Validators.required]
    });

    this.formGroup = this.fb.group({
      'login': [null, Validators.required],
      'password': [null, Validators.required],
      'captcha': [null, Validators.required] // <---- AJOUTER CAPTCHA ICI
    });
    this.UserEmail = null;
    this.subscriptions = [];
    this.openEmailPopUp = false;
    this.flag = false;
    this.loader = false;
    this.showSentMessage = false;
    
  }

  askLogin(): void {
    alert('LOGIN OK');
  }

 public redirectToresetPage(Email:any, setResetCaseNo:number):any {
      this.routerService.RedirectResetPassword(Email, setResetCaseNo);
  }

  handleSuccess(e) {
    console.log("ReCaptcha", e);
    if(e){
      this.recaptchaResponse = true;
    }
  }

  
 
  public ngOnDestroy(): void {
    _.forEach(this.subscriptions, sub => sub.unsubscribe());
  }
}


