import { Injectable } from '@angular/core';
import { EnvService } from 'src/app/services/env.service';
import { environment } from '../../../environments/environment';
import { HttpService } from './http.service';
import { ToastMessageService } from './toast-message.service';
import * as CryptoJS from 'crypto-js';


@Injectable({
  providedIn: 'root'
})
export class DocumentFetchService {

  private readonly documentBaseAPI = this.env.baseUrl + environment.services.cosecdocument.baseUrl;

  constructor(
    private httpService: HttpService,
    private env: EnvService,
    private toastMessageService: ToastMessageService,
  ) { }

  public base64ToArrayBuffer1(base64) {
    const binaryString = window.atob(base64);
    const binaryLen = binaryString.length;
    const bytes = new Uint8Array(binaryLen);
    for (let i = 0; i < binaryLen; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    } return bytes;
  }

  public saveByteArray(reportName, byte, MIME_TYPE) {
    const blob = new Blob([byte], { type: MIME_TYPE });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = reportName;
    link.click();
  }

  public saveBlob(reportName, blob) {
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = reportName;
    link.click();
  }

  // Sample Input
  // {
  //   documentName: '',
  //   documentId: '',
  // }
  public getDocument(data: any, cb: any) {
    const secretKeyBase64 = environment.secretKey;
    // Decode the Base64 secret key into bytes
    const secretKey = CryptoJS.enc.Base64.parse(secretKeyBase64);
    // Generate a random IV (initialization vector)
    const iv = CryptoJS.lib.WordArray.random(16);
    // Encrypt the documentID using the secretKey and random IV
    const encrypted = CryptoJS.AES.encrypt(data.documentId.toString(), secretKey, {
      iv: iv, // Provide the IV
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    });
    // Combine the IV and encrypted data
    const encryptedDocumentId = iv.concat(encrypted.ciphertext).toString(CryptoJS.enc.Base64);
    const input = {
      Id: encryptedDocumentId,
    };
    const url = environment.services.cosecdocument.api.getDocumentById;
    this.httpService.postDownload(this.documentBaseAPI, url, input).subscribe((result: any) => {
      this.saveBlob(data.documentName, result);
      cb();
    }, (error) => {
      if (error.status === 400 || error.status === 500) {
        this.toastMessageService.showErrorMessage('Unable to download file. Please contact administrator');
      } else {
        this.toastMessageService.showErrorMessage('Unable to download file. Please try again.');
      }
      cb();
    });
  }


  public deleteDocument(documentId: any) {
    let url = environment.services.cosecdocument.api.deleteDocumentById;
    return this.httpService.post(this.documentBaseAPI, url, documentId);
  }

}
