import { Component, HostListener } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SessionStorageService } from './shared/services/session-storage.service';
import { RouterService } from 'src/app/shared/services/router.service';
import { LoginService } from 'src/app/core/services/login.service';
import { LocalStorageService } from './shared/services/local-storage.service';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.css']
})

export class AppComponent {
	constructor(
		private sessionStorage: SessionStorageService,
		private routerService: RouterService,
		private loginService: LoginService,
		private localStorageService: LocalStorageService,
		) { }
	// @HostListener('window:beforeunload', ['$event'])
	// handleBeforeUnload(event: Event) {
	// 	const data={
	// 		Email: this.localStorageService.userDetails.Email,
	// 	  };
	// 	this.loginService.logout(data).pipe().subscribe((result) => {    
	// 		setTimeout(()=>{
	// 		},3000)           
	// 		if (result != null) {
	// 		  this.sessionStorage.flushOnLogout();
	// 		  sessionStorage.clear();
	// 		  localStorage.clear();          
	// 		}
	// 	  },
	// 		error => {  
	// 		  if (error.error !== undefined && error.error !== null) {
	// 			// this.toastMessageService.showErrorMessage(error.error.text);
	// 		  } else {
	// 			// this.toastMessageService.showErrorMessage(error.statusText);
	// 		  }
	// 		}
	// 	  );
	// }
}
