import { Component, OnInit, EventEmitter, Output, ViewChild, Input } from '@angular/core';
import { IDocumentUpload } from 'src/app/shared/models/document-upload';
import { CodesAndPoliciesService } from '../../service/codes-and-policies.service';
import { OnboardingDetailsService } from '../../service/onboarding-details.service';
import { ToastMessageService, SessionStorageService, DataService } from 'src/app/shared/services';
import { takeWhile } from 'rxjs/operators';
import { IUser } from 'src/app/shared/models/user';
import { DocumentUploadService } from '../../../document-management/services/document-upload.service';
import { DateFormatEnum } from 'src/app/shared/constants/enum';
import { FileUpload } from 'primeng/fileupload';
import { ICodePolicies, IAuditTrail, IKeyConsideration } from 'src/app/shared/models/codePolicies';
import { Router, ActivatedRoute } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import { RoleEnum } from 'src/app/shared/constants/enum';
import { SectionHeaderComponent } from 'src/app/common/components/section-header/section-header.component';

@Component({
  selector: 'app-codes-and-policies',
  templateUrl: './codes-and-policies.component.html',
  styleUrls: ['./codes-and-policies.component.scss']
})
export class CodesAndPoliciesComponent implements OnInit {

  @Output() changeDetected = new EventEmitter<any>();
  @ViewChild('codePolicyForm') codePolicyForm;
  @ViewChild('fileUpload') fileUpload: FileUpload;
  @ViewChild(SectionHeaderComponent, { static: false }) sectionHeaderComponent: SectionHeaderComponent;

  constructor(
    private codesPoliciesService: CodesAndPoliciesService,
    private onboardingService: OnboardingDetailsService,
    private toastMessageService: ToastMessageService,
    private sessionStorageService: SessionStorageService,
    private docUploadService: DocumentUploadService,
    private dataService: DataService,
    private router: Router,
    private route: ActivatedRoute,
    private confirmationService: ConfirmationService,
  ) {
    this.readOnlyFields = false;
  }
  public loader: boolean;
  codeAndPoliciesList = [];
  codeAndPoliciesListBkp: any;
  displayEditDialog = false;
  codeAndPolicydialogData: ICodePolicies = {} as ICodePolicies;
  displayConsiderationDialog = false;
  displayAddKeyConsideration = false;
  considerationList: ICodePolicies = {} as ICodePolicies;
  displayAuditTrailDialog = false;
  auditTrailData: ICodePolicies = {} as ICodePolicies;
  newKeyConsideration = '';
  localUserLoggedIn: IUser;
  isEdit = false;
  dateFormat: any;
  typeList = [];
  newAttachedPolicy: any;
  companyDetails: any;
  dateFormats = [];
  maxDateValue: any;
  readOnlyFields: boolean;
  showHeader: boolean;
  clientId: any;
  public roleEnum: any;
  public currentUserAssignment: any;
  selectedBarAction: any;
  clickAdd: boolean;

  get companyDetailsData(): any {
    return this.companyDetails;
  }

  @Input()
  set companyDetailsData(data: any) {
    if (data) {
      this.companyDetails = data;
      this.dateFormats = [
        { value: '14/03/2012', format: 'dd/MM/yy' },
        { value: '14/03/12', format: 'mm/dd/y' },
        { value: '14/3/12', format: 'dd/m/y' },
        { value: '12.14.3', format: 'yy.d.mm' },
        { value: '2012/03/14', format: 'yy/d/mm' },
        { value: '14 March 2012', format: 'd MM yy' }
      ];
      this.dateFormats.forEach(element => {
        if (element.value === this.companyDetails.DateFormat) {
          this.dateFormat = element.format;
        } else {
          // this.dateFormat = DateFormatEnum.ddMMYYYY;
        }
      });
    }
  }

  ngOnInit(): void {
    this.loader = true;
    if (this.route.snapshot.params.isReadOnly) {
      this.readOnlyFields = this.route.snapshot.params.isReadOnly === '1' ? false : true;
      this.showHeader = true;
    }

    this.roleEnum = RoleEnum;
    this.sessionStorageService.currentAssignmentDetails.subscribe((assignmentData) => {
      if (assignmentData) {
        this.currentUserAssignment = assignmentData;
        this.clientId = assignmentData.ClientId;
      }
      if (this.currentUserAssignment.RoleName === this.roleEnum.Auditor ||
        this.currentUserAssignment.RoleName === this.roleEnum.AuditCommitteeMember) {
        this.readOnlyFields = true;
      }
    });
    this.maxDateValue = new Date();
    this.dataService.changeUrl(this.router.url);
    this.typeList = [
      { label: 'Code', value: 'Code' },
      { label: 'Policy', value: 'Policy' }
    ];

    this.sessionStorageService.userDetails.pipe(takeWhile(() => true)).subscribe((userResult) => {
      this.localUserLoggedIn = userResult;
    });
    this.getCodesAndPolicies();
  }

  getCodesAndPolicies() {
    this.codesPoliciesService.getAllCodesPolicies({ ClientId: this.clientId }).subscribe(data => {
      if (data) {
        this.codeAndPoliciesList = data;
        this.codeAndPoliciesListBkp = JSON.stringify(data);
      }
      this.loader = false;
    },
      error => {
        this.loader = false;
      });
  }

  public onClickAdd() {
    this.codePolicyForm.reset();
    this.codePolicyForm.submitted = false;
    this.fileUpload.clear();
    this.codeAndPolicydialogData = {} as ICodePolicies;
    this.newAttachedPolicy = '';
    this.displayEditDialog = true;
    this.isEdit = false;
  }

  public onClickEdit(data, ri) {
    this.codePolicyForm.reset();
    this.fileUpload.clear();
    this.codePolicyForm.submitted = false;
    this.newAttachedPolicy = '';
    // this.codeAndPolicydialogData = {} as ICodePolicies;
    this.displayEditDialog = true;
    this.isEdit = true;
    setTimeout(() => {
      this.codeAndPolicydialogData = JSON.parse(JSON.stringify(data));
      this.codeAndPolicydialogData.ApprovalDate = data.ApprovalDate ? new Date(data.ApprovalDate) : null;
    }, 100);
  }

  public onClickDownloadAttachPolicy(data) {
    if (this.currentUserAssignment.RoleName !== this.roleEnum.Auditor &&
      this.currentUserAssignment.RoleName !== this.roleEnum.AuditCommitteeMember) {
      const fileName = data.AttachPolicies[data.AttachPolicies.length - 1].Document;
      const fileid = data.AttachPolicies[data.AttachPolicies.length - 1].DocumentID;
      const extension = fileName.split('.').pop();
      // this.docUploadService.downloadFile(data.AttachPolicies[data.AttachPolicies.length - 1].DocumentFileName
      //  , 'Masters', 'Client Master').subscribe(respData => {
      this.docUploadService.downloadFileById(fileid, fileName).subscribe(respData => {
        if (respData) {
          const link: HTMLAnchorElement = document.createElement('a');
          link.href = window.URL.createObjectURL(respData);
          link.download = fileName + '.' + extension;
          link.click();
        }
      }, error => {
        this.toastMessageService.showErrorMessage('Something went wrong. Please contact administrator.');
      });

      // this.docUploadService.downloadFile(data.AttachPolicies[data.AttachPolicies.length - 1].DocumentID).subscribe(respData => {
      //   if (respData) {
      //     const link: HTMLAnchorElement = document.createElement('a');
      //     link.href = window.URL.createObjectURL(respData);
      //     link.download = fileName + '.' + extension;
      //     link.click();
      //   }
      // }, error => {
      //   this.toastMessageService.showErrorMessage('Something went wrong. Please contact administrator.');
      // });
    }
  }

  public downloadFile(rowData: any): any {
    this.docUploadService.downloadFileById(rowData.DocumentID, rowData.Document).subscribe(respData => {
      if (respData) {
        const link: HTMLAnchorElement = document.createElement('a');
        link.href = window.URL.createObjectURL(respData);
        link.download = rowData.Document;
        link.click();
      }
    }, error => {
      this.toastMessageService.showErrorMessage('Something went wrong. Please contact administrator.');
    });
  }

  checkFurtherReference() {
    this.codeAndPoliciesList.forEach(element => {
      if (element.Type === 'Code' && this.codeAndPolicydialogData.UseForFurtherReference
        && element.Name !== this.codeAndPolicydialogData.Name && element.UseForFurtherReference) {
        this.confirmationService.confirm({
          message: 'Do you want to uncheck further reference code for previous checked record?',
          accept: () => {
            element.UseForFurtherReference = false;
            element.AttachPolicies = JSON.stringify(element.AttachPolicies);
            element.KeyConsideration = JSON.stringify(this.considerationList['KeyConsideration'])
            this.codesPoliciesService.updateCodePolicyDetails(element).subscribe(output => {
              this.toastMessageService.showSuccessMessage('Reference code updated Successfully');
              if (output) {
                setTimeout(() => {
                  this.codeAndPoliciesList = output;
                }, 100);
              }
            });
          },
          reject: () => {
            this.codeAndPolicydialogData.UseForFurtherReference = false;
          }
        });
      }
    });
  }

  onChangeMethod() {
    if (this.codeAndPoliciesListBkp !== JSON.stringify(this.codeAndPoliciesList)) {
      this.changeDetected.emit(true);
      if (this.showHeader) {
        this.sectionHeaderComponent.performEnablement('add');
      }
    } else {
      this.changeDetected.emit(false);
    }
  }

  public saveDialogData(codePolicyForm) {
    this.loader = true;
    if (codePolicyForm.valid) {
      this.codePolicyForm.submitted = false;
      const sendData = {
        'Id': 0,
        'ClientId': this.clientId,
        'IsDeleted': false,
        'Name': this.codeAndPolicydialogData['Name'],
        'KeyConsideration': '',
        'ApprovalDate': this.codeAndPolicydialogData['ApprovalDate'],
        'AttachPolicies': '',
        'Type': this.codeAndPolicydialogData['Type'],
        'UseForFurtherReference': this.codeAndPolicydialogData['UseForFurtherReference'] ? this.codeAndPolicydialogData['UseForFurtherReference'] : false
      };
      const arrayKeyConsideration = [];
      // key consideration save at the time of addition
      if (!this.isEdit) {
        arrayKeyConsideration.push({
          'KeyConsideration': '<p>' + this.codeAndPolicydialogData['KeyConsideration'] + '</p>',
          'DateOfModification': new Date(),
          'ModifiedBy': this.localUserLoggedIn.Email,
          'AuditVersion': 0.1
        });
        sendData.KeyConsideration = JSON.stringify(arrayKeyConsideration);
      } else {
        sendData.KeyConsideration = JSON.stringify(this.codeAndPolicydialogData['KeyConsideration']);
      }
      if (this.codeAndPolicydialogData['Id']) {
        sendData.Id = this.codeAndPolicydialogData['Id'];
      }
      // if added new file
      if (this.newAttachedPolicy) {

        const data = this.newAttachedPolicy;
        const documentObject: IDocumentUpload = {} as IDocumentUpload;
        documentObject.DocumentName = data.name;
        documentObject.DocumentFileName = data.name;
        documentObject.DocumentVersion = this.codeAndPolicydialogData['AttachPolicies'] ?
          this.codeAndPolicydialogData['AttachPolicies'][this.codeAndPolicydialogData['AttachPolicies'].length - 1].Version + 0.1 : 0.1;
        documentObject.DocumentType = 'Client Master';
        documentObject.UserAndGroupTags = '';
        documentObject.FileExtension = `.${documentObject.DocumentName.split('.').pop()}`;
        documentObject.DocumentTags = '';
        documentObject.DocumentStatus = '';
        documentObject.Comments = '';
        documentObject.ValidityStartDate = new Date();
        documentObject.ValidityEndDate = new Date(new Date().getTime() + (1000 * 60 * 60 * 24 * 365));
        documentObject.DocumentCategory = 'Masters';
        documentObject.ClientId = this.clientId;

        if (documentObject.FileExtension === '.pdf' || documentObject.FileExtension === '.docx') {
          const reader = new FileReader();
          reader.onload = (e: any) => {
            documentObject.FileData = window.btoa(e.target.result);
            this.onboardingService.saveOtherDocument(documentObject).subscribe((result: any) => {
              const documentAPIResult = {
                DocumentID: result.Id,
                // DocumentID: 12,
                Document: documentObject.DocumentName,
                DocumentFileName: documentObject.DocumentFileName,
                DataOfModification: new Date(),
                ApprovalDate: this.codeAndPolicydialogData['ApprovalDate'],
                ModifiedBy: this.localUserLoggedIn.FullName,
                Version: documentObject.DocumentVersion
              };
              let arrayDocument = [];
              if (this.codeAndPolicydialogData['AttachPolicies']) {
                arrayDocument = this.codeAndPolicydialogData['AttachPolicies'];
              }
              arrayDocument.push(documentAPIResult);
              sendData.AttachPolicies = JSON.stringify(arrayDocument);
              this.saveDocument(sendData);
              // this.codePolicyForm.reset();
              // this.fileUpload.clear();
            }, (error) => {
              this.loader = false;
              if (error && error.error && error.error.Message) {
                this.toastMessageService.showErrorMessage(error.error.Message);
              } else {
                this.toastMessageService.showErrorMessage('Error in saving Document');
              }
            });
          };
          reader.readAsBinaryString(data);
        } else {
          this.loader = false;
          this.toastMessageService.showErrorMessage('Only Pdfs and word documents are allowed.');
        }
      } else {
        if (this.codeAndPolicydialogData['AttachPolicies']) {
          sendData.AttachPolicies = JSON.stringify(this.codeAndPolicydialogData['AttachPolicies']);
          this.saveDocument(sendData);
        } else {
          this.loader = false;
          this.toastMessageService.showErrorMessage('Please attach policy');
        }

      }
    } else {
      this.loader = false;
      this.codePolicyForm.submitted = true;
    }

  }
  public removeFile(event: any): any {
    if (event) {
      this.newAttachedPolicy = '';
    }
  }

  public saveDocument(sendData) {
    this.loader = true;
    this.codesPoliciesService.updateCodePolicyDetails(sendData).subscribe(output => {
      this.displayEditDialog = false;
      this.changeDetected.emit(false);
      this.toastMessageService.showSuccessMessage('Codes/Policies Added Successfully');
      this.codeAndPolicydialogData = {} as ICodePolicies;
      this.codeAndPoliciesList = [];
      if (output) {
        this.loader = false;
        this.codeAndPoliciesList = output;
      } else {
        this.loader = false;
      }
    }, (error) => {
      this.loader = false;
    });
  }

  public saveKeyConsiderationDocument() {
    this.loader = true;
    const sendData = {
      'Id': this.considerationList['Id'],
      'ClientId': this.clientId,
      'IsDeleted': false,
      'Name': this.considerationList['Name'],
      'KeyConsideration': JSON.stringify(this.considerationList['KeyConsideration']),
      'ApprovalDate': this.considerationList['ApprovalDate'],
      'AttachPolicies': JSON.stringify(this.considerationList['AttachPolicies']),
      'Type': this.considerationList['Type'],
      'UseForFurtherReference': this.considerationList['UseForFurtherReference']
    };
    this.codesPoliciesService.updateCodePolicyDetails(sendData).subscribe(output => {
      this.changeDetected.emit(false);
      if (this.showHeader) {
        this.sectionHeaderComponent.performEnablement('save');
      }
      this.toastMessageService.showSuccessMessage('Codes/Policies Saved Successfully');
      if (output) {
        this.loader = false;
        this.codeAndPoliciesList = output;
      } else {
        this.loader = false;
      }
      this.dataService.updateClientOnboardingData(true);
    }, (error) => {
      this.loader = false;
    });
  }

  public showKeyConsideration(data, value, rowIndex?) {
    this.displayConsiderationDialog = true;
    this.considerationList = data;
    if (value === 'edit' && !this.readOnlyFields) {
      this.displayAddKeyConsideration = true;
    } else {
      if (rowIndex) {
        this.considerationList.KeyConsideration = data.AttachPolicies[rowIndex].relatedKeyConsideration;
      }
      this.displayAddKeyConsideration = false;
    }
  }

  addKeyConsideration() {
    if (this.newKeyConsideration.trim().length > 0) {
      const newKey = {
        'KeyConsideration': this.newKeyConsideration,
        'DateOfModification': new Date(),
        'ModifiedBy': this.localUserLoggedIn.Email,
        'AuditVersion': this.considerationList.AttachPolicies[this.considerationList.AttachPolicies.length - 1].Version
      } as IKeyConsideration;
      this.considerationList['KeyConsideration'].push(newKey);
      this.newKeyConsideration = '';
      this.onChangeMethod();
    }
  }

  displayAuditTrail(data) {
    this.displayAuditTrailDialog = true;
    this.auditTrailData = JSON.parse(JSON.stringify(data));
    this.auditTrailData.AttachPolicies.forEach(attachPolicy => {
      attachPolicy['relatedKeyConsideration'] = [];
      data.KeyConsideration.forEach(keyCons => {
        if (keyCons.AuditVersion === attachPolicy.Version) {
          attachPolicy['relatedKeyConsideration'].push(keyCons);
        }
      });
    });
  }

  public TakeBarAction(barActionobj: any) {
    this.selectedBarAction = barActionobj;
    this.performEnablement(barActionobj);
  }

  performEnablement(lBarAction: string) {
    switch (lBarAction) {
      case 'save': {
        this.saveKeyConsiderationDocument();
        break;
      }
      case 'submit': {
        this.clickAdd = true;
        break;
      }
    }
  }
}
