import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { EnvService } from 'src/app/services/env.service';
import { HttpService } from 'src/app/shared/services/http.service';
import { EncryptDecryptService } from 'src/app/shared/services/encrypt-decrypt.service';
import * as CryptoJS from 'crypto-js';



@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(
    private http: HttpService,
    private env: EnvService,
    private encryptService: EncryptDecryptService,
  ) { }

  private readonly apiprojectUrl = this.env.baseUrl + environment.services.cosecbase.baseUrl;

  getNotificationDetails(data) {
    const url = environment.services.cosecbase.api.getNotification;
    return this.http.post<any>(this.apiprojectUrl, url, data);
  }

  updateNotificationDetails(data) {
    const notificationData:any = data;
    const url = environment.services.cosecbase.api.addUpdateNotification;
    data.filter(allUsersMail => {
      allUsersMail.MailData = this.encrypteData(allUsersMail.MailData);
      allUsersMail.MailTo = this.encrypteData(allUsersMail.MailTo);
    });
    // notificationData[0].MailData = this.encrypteData(data[0].MailData);
    // notificationData[0].MailTo = this.encrypteData(data[0].MailTo);
    return this.http.post<any>(this.apiprojectUrl, url, data);
  }

  encrypteData(data){
    const secretKeyBase64 = environment.secretKey;
    // Decode the Base64 secret key into bytes
    const secretKey = CryptoJS.enc.Base64.parse(secretKeyBase64);
    // Generate a random IV (initialization vector)
    const iv = CryptoJS.lib.WordArray.random(16);
    // Encrypt the documentID using the secretKey and random IV
    const encrypted = CryptoJS.AES.encrypt(data.toString(), secretKey, {
      iv: iv, // Provide the IV
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    });
    // Combine the IV and encrypted data
    return iv.concat(encrypted.ciphertext).toString(CryptoJS.enc.Base64);
  }

}
